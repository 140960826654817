import Swiper from 'swiper/bundle';
import 'swiper/scss';

(function($) {

	if( $('.carousel-boxes').length > 0 ) {
		var boxCarousel = {
			init: function(){
				$('.carousel-boxes').each(function(){
                    var swiper = new Swiper($(this).find('.swiper')[0], {
                        slidesPerView: 3,  
                        spaceBetween: 24,  
                        navigation: {
                            nextEl: $(this).find('.swiper-button-next')[0],
                            prevEl: $(this).find('.swiper-button-prev')[0],
                        },
                        // scrollbar: {
                        //     el: $(this).find('.swiper-scrollbar')[0],
                        //     draggable: true,
                        //     // dragSize: 400 // Set dragger size to 400px
                        // },
                        breakpoints: {
                        
                            0: {
                                slidesPerView: 1.2,
                                spaceBetween: 14,
                            },
                        
                            768: {
                                slidesPerView: 'auto',
                                spaceBetween: 18,
                            },
                        
                            1080: {
                                slidesPerView: 'auto',
                                spaceBetween: 36,
                            }
                        },
                        // on: {
                        //     snapGridLengthChange:function(){
                        //         if( this.snapGrid.length != this.slidesGrid.length ){
                        //             this.snapGrid = this.slidesGrid.slice(0);
                        //         }
                        //     }
                        // }                     
                    });
			 	});				
			}
		};

		boxCarousel.init();	

	}
})(jQuery);