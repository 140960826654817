jQuery(document).ready(function($){
	$(".home-feat-box").hover(function(){
		$(".home-feat-box").removeClass("active-bg");
		$(this).toggleClass("active-bg");
	});
		
	$(".panel-collapse").each(function() {		
		var $_id = $(this).attr("id");
		$(this).siblings().addClass($_id);
	});
});