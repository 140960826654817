import $ from 'jquery';

(function($) {
    // init enhance details
	enhanceDetails();	
	function enhanceDetails() {
		let details = document.querySelectorAll("details");

		details.forEach((detail, d) => {

        	// quit if already processed    
			let processed = detail.classList.contains('details') ? true : false;
			let initialized = detail.classList.contains('details-init') ? true : false;
			if(processed) return false;

	        /**
	        * all wrap detail's content: 
	        * outer wrap for grid display context
	        * and inner for hidden overflow
	        */
			let outer = document.createElement("div");
			outer.classList.add("details-content");
			let inner = document.createElement("div");
			inner.classList.add("details-content-inner");

			let children = [...detail.children];
			for (let i = 0; i < children.length; i++) {
				let child = children[i];
				if (child.nodeName.toLowerCase() !== "summary") inner.append(child);
			}
			outer.append(inner);
			detail.append(outer);

	       	// replace marker icons from list-style type property    
			let summary = detail.querySelector("summary");

        	// add classes for enhanced styling
			summary.classList.add("summary");

	        /**
	        * add expanded classes for
	        * default states as specified by 
	        * "open" attribute
	        */
			let expanded = detail.hasAttribute("open");

	        // expand when "open" attribute is set
			if (expanded) {
				detail.classList.add("expanded");
				summary.classList.add("summary-expanded");
			}

    	    // inherit transition speed from CSS
			let style = window.getComputedStyle(outer);
			let delay = parseFloat(style.transitionDelay);
			let duration = parseFloat(style.transitionDuration) + delay;

        	// toggle events
			summary.addEventListener("click", (e) => {
				e.preventDefault();
				let current = e.currentTarget;
				let detail = current.closest("details");
				expanded = detail.hasAttribute("open");

          		// remove init class to enable transitions
				if(!initialized) detail.classList.remove('details-init');

          		// collapse
				if (expanded) {
					detail.classList.remove("expanded");

            		// wait according to CSS timings to apply open attribute
					setTimeout(() => {
						detail.open = false;
					}, duration * 1000);
				}
          		// expand
				else if (!expanded) {
					detail.open = true;
            		// tiny delay for transition
					setTimeout(() => {
						detail.classList.add("expanded");
						detail.classList.remove("collapsed");
					}, 10);
				}
			});

	        /**
	        * disable transitions when initializing
	        * to mitigate layout shifts
	        */
			detail.classList.add("details", "details-init");


		});
	}

})(jQuery);
